var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mx-auto mt-0" },
                [
                  _c(
                    "v-container",
                    [
                      this.status == "client"
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              items: _vm.logins,
                              headers: _vm.loginHeaderClient,
                              search: _vm.searchResult,
                              dense: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.index",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.logins.indexOf(item) + 1) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-toolbar",
                                            { attrs: { flat: "" } },
                                            [
                                              _c("v-select", {
                                                staticClass: "mx-2",
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  label: "Month",
                                                  items: _vm.monthOptions,
                                                },
                                                on: { change: _vm.fetch },
                                                model: {
                                                  value: _vm.month,
                                                  callback: function ($$v) {
                                                    _vm.month = $$v
                                                  },
                                                  expression: "month",
                                                },
                                              }),
                                              _c("v-select", {
                                                staticClass: "mx-2",
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  label: "Years",
                                                  items: _vm.yearOptions,
                                                },
                                                on: { change: _vm.fetch },
                                                model: {
                                                  value: _vm.year,
                                                  callback: function ($$v) {
                                                    _vm.year = $$v
                                                  },
                                                  expression: "year",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "mx-2",
                                                attrs: {
                                                  filled: "",
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  label: "Search",
                                                },
                                                model: {
                                                  value: _vm.searchResult,
                                                  callback: function ($$v) {
                                                    _vm.searchResult = $$v
                                                  },
                                                  expression: "searchResult",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2591393339
                            ),
                          })
                        : _vm._e(),
                      this.status == "internal"
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              items: _vm.logins,
                              headers: _vm.loginHeaderInternal,
                              search: _vm.searchResult,
                              dense: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.index",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.logins.indexOf(item) + 1) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              staticClass: "pa-3",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  label: "Month",
                                                  items: _vm.monthOptions,
                                                },
                                                on: { change: _vm.fetch },
                                                model: {
                                                  value: _vm.month,
                                                  callback: function ($$v) {
                                                    _vm.month = $$v
                                                  },
                                                  expression: "month",
                                                },
                                              }),
                                              _c("v-select", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  label: "Years",
                                                  items: _vm.yearOptions,
                                                },
                                                on: { change: _vm.fetch },
                                                model: {
                                                  value: _vm.year,
                                                  callback: function ($$v) {
                                                    _vm.year = $$v
                                                  },
                                                  expression: "year",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  filled: "",
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  label: "Search",
                                                },
                                                model: {
                                                  value: _vm.searchResult,
                                                  callback: function ($$v) {
                                                    _vm.searchResult = $$v
                                                  },
                                                  expression: "searchResult",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3643726110
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }