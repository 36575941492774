var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-0 ", attrs: { cols: "12", sm: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "px-5",
                  attrs: { elevation: "1", outlined: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-5" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "d-flex justify-center",
                              model: {
                                value: _vm.typeTab,
                                callback: function ($$v) {
                                  _vm.typeTab = $$v
                                },
                                expression: "typeTab",
                              },
                            },
                            [
                              _c("v-tab", [_vm._v(" Client ")]),
                              _c("v-tab", [_vm._v(" Internal ")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.typeTab,
                            callback: function ($$v) {
                              _vm.typeTab = $$v
                            },
                            expression: "typeTab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Unique Visitors per Month - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageUniqueVisitorsChart", {
                                        attrs: { status: "client" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Unique Visitors per Month by Platform - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageUniqueVisitorsPlatformChart", {
                                        attrs: { status: "client" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Logins per Month - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageLoginChart", {
                                        attrs: { status: "client" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Logins per Month by Platform - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageLoginPlatformChart", {
                                        attrs: { status: "client" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [_vm._v(" Top 5 Users - Filter Years ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageTopUsersChart", {
                                        attrs: { status: "client" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [_vm._v(" Login Analysis ")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("PageLoginAnalysisTable", {
                                        attrs: { status: "client" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Unique Visitors per Month - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageUniqueVisitorsChart", {
                                        attrs: { status: "internal" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Unique Visitors per Month by Platform - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageUniqueVisitorsPlatformChart", {
                                        attrs: { status: "internal" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Logins per Month - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageLoginChart", {
                                        attrs: { status: "internal" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Number of Logins per Month by Platform - Filter Years "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageLoginPlatformChart", {
                                        attrs: { status: "internal" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [_vm._v(" Top 5 Users - Filter Years ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-5" },
                                    [
                                      _c("PageTopUsersChart", {
                                        attrs: { status: "internal" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "v-card",
                                {
                                  staticClass: "px-5 py-2",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                                    },
                                    [_vm._v(" Login Analysis ")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("PageLoginAnalysisTable", {
                                        attrs: { status: "internal" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }